<template>
    <div class="mb-4">
        <fieldset>
            
            <legend v-if="legend">
                {{legend}}
                
                <span 
                    v-if="required"
                    class="required">
                    *
                    <!-- <span 
                        v-if="showRequiredWarning && items.length == 0" 
                        class="large">
                        Vedlegg er påkrevd
                    </span> -->
                </span>
            </legend>
            
            <div class="">
                
                <div 
                    :class="
                        [
                            loading ? 'opacity-25' : 'opacity-100',
                            classes && classes.uploadFrame ? classes.uploadFrame : '',
                        ]
                    "
                    class="w-full flex flex-col border border-beige-dark rounded items-center justify-center py-8 cursor-default text-gray-500 hover:text-gray-700"
                    @click="$refs.file.click()">
                    
                    <i class="fas fa-cloud-upload-alt text-4xl" />
                    <div>{{label || 'Upload File'}}</div>
                </div>
                
                <div 
                    v-for="item in items"
                    :key="item.name+'-'+item.size"
                    :title="item.name"
                    class="py-2 flex items-center">
                    
                    <div class="flex-none w-6">
                        <i 
                            class="fas text-lg text-black opacity-25" 
                            :class="item.name | fileTypeIcon"
                        />
                    </div>
                    
                    <div class="flex-none w-48 pr-4">
                        <div class="truncate max-w-full opacity-50">
                            {{item.name}}
                        </div>
                    </div>
                    
                    <div class="flex-grow relative -mt-2">
                        <div 
                            class="absolute bg-gray-200 h-2 rounded w-full">
                        </div>
                        <div 
                            class="absolute bg-blue-500 h-2 rounded"
                            :style="`width: ${item.completed}%;`">
                            
                        </div>
                    </div>
                </div>
            </div>
            
            <input 
                type="file" 
                ref="file" 
                :multiple="multiple"
                @change="onDrop" 
                class="hidden"
            />
        </fieldset>
        
        
    </div>
</template>

<script>
    export default {
        props: [
            'name',
            'label',
            'value',
            'legend',
            'classes',
            'multiple',
            'required',
            'showRequiredWarning',
        ],
        
        data(){
            return {
                items: [],
                loading: false,
            }
        },
        
        filters: {
            fileTypeIcon( nameRaw ){
                const name = nameRaw.split('.').reverse()[0].toLowerCase();
                
                switch (name) {
                    case 'pdf':
                        return 'fa-file-pdf';
                    case 'doc':
                    case 'docx':
                        return 'fa-file-word';
                    case 'jpg':
                    case 'jpeg':
                    case 'gif':
                    case 'png':
                    case 'svg':
                        return 'fa-file-image';
                    default:
                        return 'fa-file-alt';
                }
            }
        },
        
        methods: {
            async onDrop( event ) {
                if (!event.target.files) {
                    return
                }
                
                this.loading = true;
                let files = Array.from( event.target.files );
                
                const promises = files.map( async file => {
                    const fileData = {
                        input: file,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        presigned: null,
                        completed: 0,
                        status: 'added',
                    };
                
                    this.items.push( fileData );
                
                    return await this.uploadFileLocal( fileData );
                });
                
                const promiseAllResponse = await Promise.all( promises );
                this.loading = false;
            },
            
            async uploadFileLocal(fileData) {
                try {
                    const presignedResponse = await this.filesUpload( fileData );
                    
                    fileData.params = presignedResponse.params;
                    fileData.presigned = presignedResponse.presigned;
                    fileData.file = presignedResponse.file;
                    
                    const config = {
                        headers: {
                            'Content-Type': fileData.type
                        },
                        onUploadProgress: (progressEvent) => {
                            const completed = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                            fileData.completed = completed;
                            
                            if (completed == 100){
                                fileData.status = 'success';
                            }
                            else {
                                fileData.status = 'uploading';
                            }
                        }
                    };
                    
                    let payload;
                
                    if (fileData.input.content) {
                        payload = fileData.input.content;
                    }
                    else {
                        payload = fileData.input;
                    }
                    
                    // NB: s3 preflight upload is not default CORS:
                    // https://docs.aws.amazon.com/AmazonS3/latest/dev/cors.html
                    const uploadResponse = await this.$axios.put( fileData.presigned, payload, config );
                    // fileData.uploadResponse = uploadResponse;
                    // console.log('uploadResponse', uploadResponse);
                    // console.log('fileData', fileData);
                    // console.log('fileData.file', fileData.file);
                    this.$emit('input', fileData.file);
                    
                    return fileData;
                } 
                catch (error) {
                    console.log('error', error);
                    fileData.status = 'error';
                }
            }
        }
    }
</script>